<template>
  <en-dialog :model-value="modelValue" width="80%" title="配件生产单列表" @close="$emit('update:model-value', false)">
    <tabs-maintain
      v-model="tabs.active"
      :ajax="{ action: 'GET /enocloud/common/lookup/:lookupType', params: (params) => (params.paths = ['MAFPRDSTAT']) }"
      :props="{ label: 'message', value: 'code' }"
      :method="table.get"
    ></tabs-maintain>

    <en-table :data="table.data" :loading="table.loading" :height="600" pagination :paging="table.paging">
      <en-table-column label="单号" prop="serialNo"></en-table-column>
      <en-table-column label="状态" prop="status.message"></en-table-column>
      <en-table-column label="生产配件编号" prop="goodsSpecification.goods.serialNo"></en-table-column>
      <en-table-column label="生产配件名称" prop="goodsSpecification.goods.name"></en-table-column>
      <en-table-column label="生产数量" prop="count"></en-table-column>
      <en-table-column label="规格" prop="goodsSpecification.name"></en-table-column>
      <en-table-column label="配件总金额" prop="primeCost">
        <template #default="{ row }: { row: EnocloudManufacturingDefinitions['ManufacturingProductionDto'] }">
          {{ formatMoney(row.primeCost) }}
        </template>
      </en-table-column>
      <en-table-column label="制单人" prop="preparedBy.name"></en-table-column>
      <en-table-column label="制单日期" prop="preparedDatetime">
        <template #default="{ row }: { row: EnocloudManufacturingDefinitions['ManufacturingProductionDto'] }">
          {{ formatDate(row.preparedDatetime) }}
        </template>
      </en-table-column>
      <en-table-column label="审核人" prop="producedBy.name"></en-table-column>
      <en-table-column label="审核日期">
        <template #default="{ row }: { row: EnocloudManufacturingDefinitions['ManufacturingProductionDto'] }">
          {{ formatDate(row.producedDatetime) }}
        </template>
      </en-table-column>
      <en-table-column label="备注" prop="comment"></en-table-column>
    </en-table>
  </en-dialog>
</template>

<script lang="ts">
export default factory({
  props: {
    modelValue: Boolean
  },

  emits: {
    'update:model-value': (value: boolean) => typeof value === 'boolean'
  },

  config: {
    children: {
      tabs: {
        active: ''
      },
      table: {
        ajax: {
          get: {
            action: 'GET /enocloud/manufacturing/production',
            data: 'array',
            loading: true,
            pagination: true,
            params(params) {
              params.payload = { statusCode: this.tabs.active }
            }
          }
        }
      }
    }
  },

  mounted() {
    this.table.get()
  }
})
</script>
